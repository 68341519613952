

import React, { useState, useEffect } from "react";
import { menu_items } from "../data/main_menu";
import { Icon } from '@iconify/react';
import {useTranslation} from "react-i18next";
import LanguageSelector from "./LanguageSelector";
import logo from "../img/logo.png";


export default function Menu() {
    const [isOpen, setIsOpen] = useState(false);

    const toggleHamburger = () =>{
        setIsOpen(!isOpen);
        document.body.classList.toggle('menu--shown');
    }

    useEffect(() => {
        var scroll = 0;
        var previousScroll = 0;
        document.querySelectorAll('.menu_item').forEach(item =>{
            item.addEventListener('click', function(){
                setIsOpen(!isOpen);
                console.log(isOpen);
                document.body.classList.remove('menu--shown');
            })
        })
        const onScroll = () =>{
            var scroll = window.scrollY;
    
            if(scroll > 0){
                document.body.classList.add('scrolled');
                if(previousScroll < scroll){
                    document.body.classList.add('scrolldown');
                }else{
                    document.body.classList.remove('scrolldown');
                }
                previousScroll = scroll;
            }
            else{
                document.body.classList.remove('scrolled');
            }
        }
        // clean up code
        window.removeEventListener('scroll', onScroll);
        window.addEventListener('scroll', onScroll, { passive: true });
        return () => window.removeEventListener('scroll', onScroll);
    }, []);

    const { t, i18n, ready } = useTranslation();
    if (!ready) return "loading translations...";
    const menuItems = t("menu_items", { returnObjects: true });

    return (
        <header className={"main_menu" + (isOpen ? ' menu--shown' : '')}>
            <div className="wrap main_menu__top">
                <div className="main_menu__left">
                    <img className="logo" src={logo} alt="Sounthida Kong"/>
                </div>
                <div className="main_menu__right">
                    <div className="switch_lang">
                        <LanguageSelector></LanguageSelector>
                    </div>
                    <div className="hamburger" onClick={toggleHamburger}>
                        <div className="icon_closed">
                            <Icon icon="mdi:remove" />
                        </div>
                        <div className="icon_opened">
                            <Icon icon="solar:hamburger-menu-broken" />
                        </div>                     
                    </div>
                </div>
            </div>
            <div
            className="main_menu__burger"
            >
                <div className="wrap">
                    <ul>
                        {menuItems.map((item) => (
                            <a
                            key={item.id}
                            href={item.anchor}
                            className="menu_item">
                                <span>{item.title}</span>
                            </a>
                        ))}
                    </ul>
                </div>
            </div>
        </header>
    );
}

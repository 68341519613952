import pfdavocats from "../img/projects/pfdavocats.webp";
import qix from "../img/projects/qix.webp";
import sistarsrecipes from "../img/projects/sistarsrecipes.webp";
import incursion from "../img/projects/incursion.webp";
import ritabaga from "../img/projects/ritabaga.webp";
import maplejoe from "../img/projects/maplejoe.webp";

import pfdavocats_600 from "../img/projects/pfdavocats_600.webp";
import qix_600 from "../img/projects/qix_600.webp";
import sistarsrecipes_600 from "../img/projects/sistarsrecipes_600.webp";
import incursion_600 from "../img/projects/incursion_600.webp";
import ritabaga_600 from "../img/projects/ritabaga_600.webp";
import maplejoe_600 from "../img/projects/maplejoe_600.webp";

export const imagesList = [
  {
    id: "sistarsrecipes",
    src: sistarsrecipes,
    mobile_src: sistarsrecipes_600,
    alt: "Sistars recipes",
  },
  {
    id: "pfdavocats",
    src: pfdavocats,
    mobile_src: pfdavocats_600,
    alt: "PFD",
  },
  {
    id: "qix",
    src: qix,
    mobile_src: qix_600,
    alt: "QIX",
  },
  {
    id: "incursion",
    src: incursion,
    mobile_src: incursion_600,
    alt: "Incursion Voyages",
  },
  {
    id: "ritabaga",
    src: ritabaga,
    mobile_src: ritabaga_600,
    alt: "Rita Baga",
  },
  {
    id: "maplejoe",
    src: maplejoe,
    mobile_src: maplejoe_600,
    alt: "Maple Joe US - CA",
  },
];

export default imagesList;

import React, {useState} from "react";
import i18n from '../i18n';

const LanguageSelector = () => {
    
    const [selectedLanguage, setSelectedLanguage] = useState(i18n.language); // i18n.language contains the language assigned to lng in i18n.js file.

    const chooseLanguage = (e) => {
        e.preventDefault();
        let lang = document.querySelector('.langBtn').querySelector('.lang').getAttribute('value');        
        i18n.changeLanguage(lang);   // i18n.changeLanguage() is used to change the language assigned to lng in i18n.js file.
        setSelectedLanguage(lang);
    
    }

    return (
        <div className="langBtn" onClick={chooseLanguage}>
            {selectedLanguage === "en" &&
                <span className="lang" value="fr">FR</span>
            }
             {selectedLanguage === "fr" &&
                <span className="lang" value="en">EN</span>
            }
        </div>
    );
};

export default LanguageSelector;
//import { CodeIcon } from "@heroicons/react/solid";
import React, {useRef, useEffect, useLayoutEffect, useState, useCallback} from "react";
//import { projects, project_section, imagesList } from "../data/projects";
import { gsap } from "gsap";
import ScrollTrigger from 'gsap/ScrollTrigger'
import { useGSAP } from "@gsap/react";


gsap.registerPlugin(ScrollTrigger);



export default function GsapEffects() {

  useLayoutEffect(() => {
    let ctx = gsap.context(() => {
      let targets = gsap.utils.toArray(".animated");
      targets.forEach(target => {
        let delay = 0;
        if(target.classList.contains('delay--1')){
          delay = 0.1;
        }
        if(target.classList.contains('delay--2')){
          delay = 0.2;
        }
        if(target.classList.contains('delay--3')){
          delay = 0.3;
        }
        
        if(target.classList.contains('fade')){
          gsap.from(target, {
            scrollTrigger: {
              trigger: target,
            },
            opacity: 0,
            delay: delay,
          });
        }
        if(target.classList.contains('fade-up')){
          gsap.from(target, {
            scrollTrigger: {
              trigger: target,
            },
            y: "50px",
            opacity: 0,
            delay: delay,
          });
        }
        if(target.classList.contains('fade-right')){
          gsap.from(target, {
            scrollTrigger: {
              trigger: target,
            },
            x: "50px",
            opacity: 0,
            delay: delay,
          });
        }
        if(target.classList.contains('fade-left')){
          gsap.from(target, {
            scrollTrigger: {
              trigger: target,
            },
            x: "-50px",
            opacity: 0,
            delay: delay,
          });
        }
      });      
    });
    return () => ctx.revert(); // <- cleanup!
  }, []);
}

import React, { useState } from "react";
import { menu_items } from "../data/main_menu";
import { Icon } from '@iconify/react';
import Profile from '../img/profile.webp';
import parse from 'html-react-parser';
import { useTranslation } from "react-i18next";


function Hero() {
    const { t, i18n, ready } = useTranslation();

    if (!ready) return "loading translations...";

    const menuItems = t("menu_items", { returnObjects: true });

    return (
        <section className="hero">
            <div className="wrap">
                <div className="hero__wrapper">
                    <div className="hero__left">
                        <img src={Profile} alt="Sounthida Kong - Dessin" />
                    </div>
                    <div className="hero__right">
                        <div className="text">
                            <h1 className="fade-up animated">{t("hero.title")}</h1>
                            <span className="subtitle fade-up animated delay--1">{t("hero.subtitle")}</span>
                            <span className="wysiwyg big-text fade-up animated delay--2">{parse(t("hero.text"))}</span>
                        </div>
                        <div className="hero__mobile">
                            <img src={Profile} alt="Sounthida Kong - Dessin"/>
                        </div>
                        <ul>
                        {menuItems.map((item) => (
                            <a
                                data-cursor
                                key={item.id}
                                href={item.anchor}
                                className="hero__item">
                                    <span className={"fade-left animated delay--" + item.id}>{item.title}</span>
                                </a>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        </section>
    );
}


export default Hero;
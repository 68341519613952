//import { CodeIcon } from "@heroicons/react/solid";
import React, {
  useRef,
  useEffect,
  useLayoutEffect,
  useState,
  useCallback,
} from "react";
//import { projects, project_section, imagesList } from "../data/projects";
import { gsap } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import { useGSAP } from "@gsap/react";
import { useTranslation } from "react-i18next";
import parse from "html-react-parser";
import { Icon } from "@iconify/react";

// PROJECT IMAGES
import imagesList from "../data/imagesList.js";

gsap.registerPlugin(ScrollTrigger);

export default function Projects() {
  const container = useRef();
  //const {mouseOver, mouseOut, hovered} = useHover();
  const [activeProject, setProject] = useState(0);

  const mouseOver = (event) => {
    let projectID;
    document.querySelectorAll(".project").forEach((el) => {
      el.classList.remove("active");
    });
    if (event.target.classList.contains("project")) {
      if (window.innerWidth > 1024) {
        event.target.classList.add("active");
      }
      projectID = event.target.getAttribute("data-key");
    } else {
      if (window.innerWidth > 1024) {
        event.target.closest(".project").classList.add("active");
      }
      projectID = event.target.closest(".project").getAttribute("data-key");
    }
    setProject(projectID);
  };

  useLayoutEffect(() => {
    let ctx = gsap.context(() => {
      ScrollTrigger.matchMedia({
        "(min-width: 1024px)": function () {
          gsap.to(".margin_animation", {
            scrollTrigger: {
              scrub: 1,
              start: "-700px",
              end: `+=400px`,
              trigger: "#projects",
            },
            padding: "0px",
            maxWidth: "100%",
          });
          gsap.from(".border_radius", {
            scrollTrigger: {
              scrub: 1,
              start: "-800px",
              end: `+=400px`,
              trigger: "#projects",
            },
            y: "150px",
          });
          gsap.from(".border_radius", {
            scrollTrigger: {
              scrub: 1,
              start: "-800px",
              end: `+=400px`,
              trigger: "#projects",
            },
            borderRadius: "60px",
          });
        },
      });
    });
    return () => ctx.revert(); // <- cleanup!
  }, []);

  const { t, i18n, ready } = useTranslation();
  const projects_list = t("projects.projects", { returnObjects: true });

  return (
    <section id="projects" className="projects" ref={container}>
      <div className="wrap margin_animation first_wrapper">
        <div className="projects__wrapper">
          <div className="wrap margin_animation">
            <div className="second_wrapper border_radius">
              <div className="wrap">
                <div className="projects__top">
                  <h2 className="animated fade-up">
                    {t("projects.project_section.title")}
                  </h2>
                  <span className="subtitle animated fade-up delay--1">
                    {t("projects.project_section.subtitle")}
                  </span>
                  <div className="wysiwyg big-text animated fade-up delay--2">
                    {parse(t("projects.project_section.description"))}
                  </div>
                </div>
                <div className="projects__list">
                  <div className="projects__left">
                    {projects_list.map((project) => (
                      <div
                        rel="noreferrer"
                        className="project fade-right animated"
                        href={project.link}
                        target="_blank"
                        key={project.id}
                        data-key={project.id}
                        onMouseOver={mouseOver}
                      >
                        <a
                          href={project.link}
                          rel="noreferrer"
                          className="img"
                          target="_blank"
                        >
                          {imagesList.map(
                            (image) =>
                              image.id == project.id && (
                                <img
                                  key={image.id}
                                  src={image.mobile_src}
                                  alt={image.alt}
                                />
                              )
                          )}
                        </a>
                        <div className="content">
                          <h3 className="">
                            <a
                              href={project.link}
                              rel="noreferrer"
                              target="_blank"
                            >
                              {project.title}
                            </a>
                          </h3>
                          <span className="categories">
                            {project.categories.map((category) => (
                              <span className="tag">{category.title}</span>
                            ))}
                          </span>
                          {project.description && (
                            <span className="description">
                              {parse(project.description)}
                            </span>
                          )}
                          <span className="subtitle">{project.subtitle}</span>
                          <span className="small_text">{project.date}</span>
                          <div className="arrow">
                            <Icon icon="ic:baseline-chevron-right" />
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="projects__right">
                    <div className="project_img default"></div>
                    {projects_list.map((project) => (
                      <div
                        className={
                          activeProject == project.id
                            ? "project_img active"
                            : "project_img"
                        }
                        key={project.id}
                        href={project.link}
                        target="_blank"
                        data-id={project.id}
                      >
                        <a
                          href={project.link}
                          className="link"
                          rel="noreferrer"
                          target="_blank"
                        ></a>
                        {imagesList.map(
                          (image) =>
                            image.id == project.id && (
                              <img
                                key={image.id}
                                src={image.src}
                                alt={image.alt}
                              />
                            )
                        )}
                        <div className="project__content">
                          <span className="categories">
                            {project.categories.map((category) => (
                              <span className="tag">{category.title}</span>
                            ))}
                          </span>
                          {project.description && (
                            <span className="description">
                              {parse(project.description)}
                            </span>
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

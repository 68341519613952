import React from "react";
import ReactDOM from "react-dom/client";
import "./css/main.min.css";
import "./script.js";
//import App from './App';
import Menu from "./components/Menu";
import Hero from "./components/Hero";
import About from "./components/About";
import Projects from "./components/Projects";
import Contact from "./components/Contact";
import GsapEffects from "./components/GsapAnimation";

function App() {
  return (
    <div className="App">
      <div className="body_curtain"></div>
      <Menu />
      <Hero />
      <About />
      <Projects />
      <Contact />
      <GsapEffects />
    </div>
  );
}

export default App;
/**
 * 
 *     <Menu />
      <Hero />
      <About />
      <Projects />
      <Contact />
 */

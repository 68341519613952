import React, { useEffect, useState } from "react";
import Profile from "../img/profile.png";
import { Icon } from "@iconify/react";
import { useTranslation } from "react-i18next";
import parse from "html-react-parser";
import i18n from "../i18n";

export default function About() {
  let activeIcon = "competence";
  const [icon, setIcon] = useState("competence");

  useEffect(() => {
    const onScroll = () => {
      if (window.innerWidth > 1024) {
        let competence_scrolltop = document
          .querySelector(".about__sections .section:first-child")
          .getBoundingClientRect().top;
        let formation_scrolltop = document
          .querySelector(".about__sections .section:nth-child(2)")
          .getBoundingClientRect().top;
        let experience_scrolltop = document
          .querySelector(".about__sections .section:nth-child(3)")
          .getBoundingClientRect().top;

        let icon = "competence";

        if (experience_scrolltop - 100 <= 0) {
          icon = "experience";
        } else if (formation_scrolltop - 100 <= 0) {
          icon = "formation";
        }
        setIcon(icon);
      }
    };
    // clean up code
    window.removeEventListener("scroll", onScroll);
    window.addEventListener("scroll", onScroll, { passive: true });
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  const { t, i18n, ready } = useTranslation();
  const about_sections = t("about.sections", { returnObjects: true });

  return (
    <section id="about" className="about">
      <div className="wrap">
        <div className="about__wrapper">
          <div className="about__left">
            <div className="header">
              <h2 className="fade-up animated">{t("about.title")}</h2>
              <span className="subtitle fade-up animated delay--1">
                {icon === "competence" &&
                  i18n.language === "fr" &&
                  "Compétences"}
                {icon === "formation" && i18n.language === "fr" && "Formation"}
                {icon === "experience" &&
                  i18n.language === "fr" &&
                  "Expérience"}

                {icon === "competence" && i18n.language === "en" && "Skills"}
                {icon === "formation" && i18n.language === "en" && "Education"}
                {icon === "experience" &&
                  i18n.language === "en" &&
                  "Experience"}
              </span>
              <span className="competence_icon icon_animation animated fade delay--2">
                <Icon
                  icon="ant-design:laptop-outlined"
                  color="#ffbab5"
                  style={{ display: icon === "competence" ? "block" : "none" }}
                />
                <Icon
                  icon="tabler:school"
                  color="#ffbab5"
                  style={{ display: icon === "formation" ? "block" : "none" }}
                />
                <Icon
                  icon="mdi:work-outline"
                  color="#ffbab5"
                  style={{ display: icon === "experience" ? "block" : "none" }}
                />
              </span>

              <span className="wysiwyg big-text fade-up animated delay--3">
                {parse(t("about.description"))}
              </span>
            </div>
            <div className="socials">
              <a
                className="fade-up animated delay--1"
                target="_blank"
                href="https://www.linkedin.com/in/sounthida-kong"
                title="LinkedIn"
                rel="noreferrer"
              >
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                  <path d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z"></path>
                </svg>
              </a>
              <a
                className="fade-up animated delay--2"
                target="_blank"
                href="https://www.instagram.com/sounthi_kg/"
                title="Instagram"
                rel="noreferrer"
              >
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                  <path d="M224,202.66A53.34,53.34,0,1,0,277.36,256,53.38,53.38,0,0,0,224,202.66Zm124.71-41a54,54,0,0,0-30.41-30.41c-21-8.29-71-6.43-94.3-6.43s-73.25-1.93-94.31,6.43a54,54,0,0,0-30.41,30.41c-8.28,21-6.43,71.05-6.43,94.33S91,329.26,99.32,350.33a54,54,0,0,0,30.41,30.41c21,8.29,71,6.43,94.31,6.43s73.24,1.93,94.3-6.43a54,54,0,0,0,30.41-30.41c8.35-21,6.43-71.05,6.43-94.33S357.1,182.74,348.75,161.67ZM224,338a82,82,0,1,1,82-82A81.9,81.9,0,0,1,224,338Zm85.38-148.3a19.14,19.14,0,1,1,19.13-19.14A19.1,19.1,0,0,1,309.42,189.74ZM400,32H48A48,48,0,0,0,0,80V432a48,48,0,0,0,48,48H400a48,48,0,0,0,48-48V80A48,48,0,0,0,400,32ZM382.88,322c-1.29,25.63-7.14,48.34-25.85,67s-41.4,24.63-67,25.85c-26.41,1.49-105.59,1.49-132,0-25.63-1.29-48.26-7.15-67-25.85s-24.63-41.42-25.85-67c-1.49-26.42-1.49-105.61,0-132,1.29-25.63,7.07-48.34,25.85-67s41.47-24.56,67-25.78c26.41-1.49,105.59-1.49,132,0,25.63,1.29,48.33,7.15,67,25.85s24.63,41.42,25.85,67.05C384.37,216.44,384.37,295.56,382.88,322Z"></path>
                </svg>
              </a>
              <a
                className="fade-up animated delay--3"
                target="_blank"
                href="https://www.youtube.com/channel/UC2Q6KdG6JKSD7l7ciUVnUKA"
                title="Youtube"
                rel="noreferrer"
              >
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                  <path d="M186.8 202.1l95.2 54.1-95.2 54.1V202.1zM448 80v352c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V80c0-26.5 21.5-48 48-48h352c26.5 0 48 21.5 48 48zm-42 176.3s0-59.6-7.6-88.2c-4.2-15.8-16.5-28.2-32.2-32.4C337.9 128 224 128 224 128s-113.9 0-142.2 7.7c-15.7 4.2-28 16.6-32.2 32.4-7.6 28.5-7.6 88.2-7.6 88.2s0 59.6 7.6 88.2c4.2 15.8 16.5 27.7 32.2 31.9C110.1 384 224 384 224 384s113.9 0 142.2-7.7c15.7-4.2 28-16.1 32.2-31.9 7.6-28.5 7.6-88.1 7.6-88.1z"></path>
                </svg>
              </a>
            </div>
          </div>
          <div className="about__right">
            <div className="about__sections">
              {about_sections.map((section) => (
                <div className="section " key={section.id}>
                  <h3 className="">
                    <div className="title fade-right animated">
                      {section.title}
                    </div>
                  </h3>
                  {section.id == 1 && (
                    <div className="items">
                      <ul>
                        {section.items.map((item) => (
                          <li className="fade-up animated" key={item.id}>
                            {item.title}
                            {item.id == 7 && (
                              <span className="years small_text librairies">
                                {item.libraries.map((item) => (
                                  <span className="title">{item.title}</span>
                                ))}
                              </span>
                            )}
                            {item.id !== 7 && (
                              <span className="years small_text">
                                ( {item.year} )
                              </span>
                            )}
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                  {section.id == 2 && (
                    <div className="items">
                      {section.items.map((item) => (
                        <div className="item fade-up animated" key={item.title}>
                          <span className="title">{item.title}</span>
                          <span className="description bold">
                            {item.description}
                          </span>
                          <span className="small_text">{item.year}</span>
                        </div>
                      ))}
                    </div>
                  )}
                  {section.id == 3 && (
                    <div className="items">
                      {section.items.map((item) => (
                        <div className="item fade-up animated" key={item.title}>
                          <span className="title with_arrow">
                            <Icon icon="ic:baseline-chevron-right" />
                            {item.title}
                          </span>
                          <span className="description bold">
                            {item.company}
                          </span>
                          <span className="small_text">{item.year}</span>
                          <span className="tasks">{item.description}</span>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export const menu_items = [
    {
      id: 1,
      title: "À propos",
      anchor: "#about"
    },
    {
      id: 2,
      title: "Mes projets",
      anchor: "#projects"
    },
    {
      id: 3,
      title: "Contact",
      anchor: "#contact"
    }
  ];
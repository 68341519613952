import React, { useState, useRef } from "react";
import { Icon } from "@iconify/react";
import { useTranslation } from "react-i18next";
import emailjs from "@emailjs/browser";

export default function Contact() {
  const form = useRef();

  const { t, i18n, ready } = useTranslation();
  if (!ready) return "loading translations...";
  const contactInputs = t("contact.inputs", { returnObjects: true });
  const contactErrors = t("contact.form_messages", { returnObjects: true });

  const sendEmail = (e) => {
    e.preventDefault();

    // INPUT VALIDATIONS
    var errors = 0;
    var inputs = document.querySelectorAll(
      'input:not([type="submit"]), textarea'
    );

    inputs.forEach((input) => {
      if (input.closest(".input_wrapper").querySelector(".error") !== null) {
        input.closest(".input_wrapper").querySelector(".error").remove();
      }
      // CHECK IF INPUT IS EMPTY
      if (input.value == "") {
        errors += 1;
        // send empty input error message
        const errorDiv = document.createElement("div");
        errorDiv.setAttribute("class", "error");
        const errorContent = document.createTextNode(contactErrors[0].message);
        errorDiv.appendChild(errorContent);
        input.closest(".input_wrapper").append(errorDiv);
      } else {
        // validate email address
        if (input.getAttribute("name") == "from_email") {
          var re = /\S+@\S+\.\S+/;
          if (!re.test(input.value)) {
            errors += 1;
            const errorDiv = document.createElement("div");
            errorDiv.setAttribute("class", "error");
            const errorContent = document.createTextNode(
              contactErrors[1].message
            );
            errorDiv.appendChild(errorContent);
            input.closest(".input_wrapper").append(errorDiv);
          }
        }
      }
    });

    if (errors < 1) {
      emailjs
        .sendForm("service_f3if9jw", "template_ic5mctj", form.current, {
          publicKey: "_aceJ6--kaKiI3-j7",
        })
        .then(
          () => {
            document.querySelector(".contact form").remove();
            document.querySelector(".contact .failed_message").style.display =
              "none";
            document.querySelector(".contact .success_message").style.display =
              "block";
          },
          (error) => {
            document.querySelector(".contact .failed_message").style.display =
              "block";
          }
        );
    }
  };

  return (
    <footer className="contact" id="contact">
      <h2 className="fade-up animated">{t("contact.title")}</h2>
      <div className="wrap">
        <div className="contact__wrapper">
          <div className="contact__top">
            <span className="wysiwyg fade-up animated delay--1">
              {t("contact.description")}
            </span>
          </div>
          <div className="contact__bottom fade animated delay--2">
            <form ref={form} onSubmit={sendEmail}>
              {contactInputs.map((input) => (
                <span key={input.id} className={`${input.type} input_wrapper`}>
                  {input.type === "submit" && (
                    <input
                      type={input.type}
                      noValidate
                      name={input.name}
                      id=""
                      value={input.placeholder}
                    />
                  )}
                  {input.type === "textarea" && (
                    <textarea
                      name={input.name}
                      id=""
                      cols="30"
                      rows="5"
                      placeholder={input.placeholder}
                    ></textarea>
                  )}
                  {input.type !== "textarea" && input.type !== "submit" && (
                    <input
                      type={input.type}
                      noValidate
                      name={input.name}
                      id=""
                      placeholder={input.placeholder}
                    />
                  )}
                </span>
              ))}
            </form>
            <div className="failed_message final_message">
              {contactErrors[3].message}
            </div>
            <div className="success_message final_message">
              {contactErrors[2].message}
            </div>
          </div>
        </div>
      </div>
      <div className="wrap footer__legals">{t("footer_items.title")}</div>
    </footer>
  );
}
